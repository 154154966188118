<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main" v-loading="isLoading">
					<ucMenu num="1"></ucMenu>
					<div class="right_con">
						<div class="dianpu_main">
							<div class="dp_title">
								<p class="fb">我的店铺</p>
								<div class="auth_btn">
									<p class="add_new" @click="openAuthorize(authorize_url)">新增抖店</p>
									<!-- <p class="add_new" @click="openAuthorize(kuaidian_authorize_url)">新增快店</p> -->
								</div>
							</div>
							<ul class="dp_list">
								<li v-for="(item,index) in list" :key="index">
									<div class="logo">
										<img class="coverimg" v-if="item.shop_type==1" src="../../assets/images/logo.png" />
										<img class="coverimg" v-if="item.shop_type==2" src="../../assets/images/kuaidian.png" />
									</div>
									<div class="con_box">
										<p class="dp_name">{{item.shop_name}}</p>
										<p class="dp_status">星好货铺品：
											<span class="red" v-if="item.state_app.val==0">{{item.state_app.text}}</span>
											<span class="green" v-if="item.state_app.val==1">{{item.state_app.text}}</span>
											<a target="_blank" style="margin-left: 10px;" href="https://fxg.jinritemai.com/ffa/mopen/application"><span class="red">续订</span></a>
										</p>
										<p class="dp_time">应用到期时间：{{item.app_expire_time_text}}</p>
										<p class="btn_auth red" @click="openAuthorize(item.authorize_url)">重新授权</p>
										<br>
										<p class="dd_name">星好货打单发货</p>
										<div v-if="item.mod_date_b>0">
											<p class="dp_status" style="margin-top: 10px;">授权状态：
												<span class="red" v-if="item.state_b.val==0">{{item.state_b.text}}</span>
												<span class="green" v-if="item.state_b.val==1">{{item.state_b.text}}</span>
												<a target="_blank" style="margin-left: 10px;" href="https://fxg.jinritemai.com/ffa/mopen/application"><span class="red">续订</span></a>
											</p>
											<p class="dp_time">应用到期时间：{{item.app_expire_time_b_text}}</p>
											<p class="btn_auth red" @click="openAuthorize(item.authorize_url_b)">重新授权</p>
										</div>
										<div v-else>
											<p class="btn_auth blue" @click="openAuthorize(item.authorize_url_b)">立即授权</p>
										</div>
									</div>
									<div class="opts">
										<!-- <span @click="openAuthorize(item.authorize_url)">重新授权</span>
										<em>/</em> -->
										<span @click="bindDel(item.shop_id)">关店</span>
									</div>
								</li>
							</ul>
							<el-empty v-if="page_data.total==0&&!isLoading" description="未添加任何店铺"></el-empty>
						</div>
						<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" />
					</div>
				</div>
			</div>
		</div>
		<!-- <el-dialog title="授权店铺" :visible.sync="dialog.authorizeDialog" width="30%" center >
		  <div class="opts_wrap">
		  	<div class="opts_line">
		  		<p class="txt">抖店service id</p>
		  		<div class="con">
		  			<p class="t"></p>
		  			<el-input class="w180 ml8" v-model="service_id"></el-input>
		  			<p class="t ml8" @click="openAuthorize('https://op.jinritemai.com/docs/question-docs/116/427')">如何获取service id?</p>
		  		</div>
		  	</div>
		  	<div class="btn_wrap">
		  		<p class="btn" @click="doAdd()">去授权</p>
		  		<p class="btn" @click="dialog.authorizeDialog = false">取消</p>
		  	</div>
		  </div>
		</el-dialog> -->
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import { Loading } from 'element-ui';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import Pagination from '../../components/pagination/pagination';
import ucMenu from '../../components/ucenterMenu/ucmenu';

export default {
	name: "ucenter",
	data() {
		return {
			isLoading: false,
			list:[],
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			authorize_url:'',
			kuaidian_authorize_url: '',
			dialog: {
				authorizeDialog:false,
			},
			service_id:'',
			itv_auto_refresh:0
		}
	},
	created() {
		this.getList()
		this.autoRefresh()
	},
	destroyed() {
		if(this.itv_auto_refresh){
			clearInterval(this.itv_auto_refresh)
		}
	},
	components:{
		myHead,myFoot,ucMenu,Pagination
	},
	methods: {
		...mapActions({
			getDdShopList:"ucenter/getDdShopList",
			opDdShopDel:"ucenter/opDdShopDel"
		}),
		...mapMutations({
			OUT_LOGIN:"login/OUT_LOGIN"
		}),
		//自动刷新
		autoRefresh(){
			let that = this
			that.itv_auto_refresh = setInterval(function(){
				that.getList(false)
			},5000)
			
		},
		//获取店铺列表
		getList(showLoading = true){
			if(showLoading) this.isLoading = true
			let param = {limit:this.page_data.limit,page:this.page_data.page,state:this.state}
			Object.assign(param, this.searchData)
			// console.log(param)
			this.getDdShopList({data:param,success:(res)=>{
				if(res.code===200){
					// console.log('get shop list',res)
					this.authorize_url = res.data.authorize_url
					this.kuaidian_authorize_url = res.data.kuaidian_authorize_url
					this.list = res.data.list_data.data
					this.page_data.total = res.data.list_data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				if(res.code===201){
					this.OUT_LOGIN()
				}
				this.isLoading = false
			}})
		},
		//弹窗授权 废
		bindAdd(){
			this.service_id = ''
			this.dialog.authorizeDialog = true
		},
		//确定授权
		doAdd(){
			let service_id = this.service_id
			if(service_id == ''){
				this.$message({message: '请输入抖店service id',type: 'info',});
				return
			}
			let authorize_url = this.authorize_url+service_id
			this.openAuthorize(authorize_url)
			this.dialog.authorizeDialog = false
		},
		//跳转授权
		openAuthorize(url){
			window.open(url, '_blank')
		},
		//关店
		bindDel(shop_id){
			let that = this
			that.$confirm('确认关闭此店铺吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				if(that.isLoading) return
				that.isLoading = true
				let param = {shop_id:shop_id}
				// console.log(param)
				that.opDdShopDel({data:param,success:(res)=>{
					if(res.code===200){
						// console.log('get shop list',res)
						that.$message({message: res.msg,type: 'success',});
						that.getList()
					} else {
						that.$message({message: res.msg,type: 'error',});
					}
					that.isLoading = false
				}})
			}).catch((res) => {
				console.log(res)
			});
			
		}
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}

.right_con{width: 980px;background: #fff;}
.dianpu_main{padding: 28px 40px;min-height: 500px;}
.dianpu_main .dp_title{display: flex;justify-content: space-between;align-items: center;}
.dp_title .add_new{width: 74px;height: 34px;box-sizing: border-box;border-radius: 6px;border: 1px solid #f0413e;display: flex;justify-content: center;align-items: center;font-size: 12px;color: #f0413e;cursor: pointer;background: #fff;margin-left: 10px;}
.auth_btn{display: flex;align-items: center;}
.dianpu_main .dp_list{padding-top: 8px;display: flex;flex-wrap: wrap;}
.dp_list>li{width: 290px;height: 250px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 6px;background: #fff;padding: 20px;position: relative;display: flex;margin: 14px 14px 0 0;}
.dp_list>li:nth-child(3n){margin-right: 0;}
.dp_list>li .logo{width: 60px;height: 60px;border-radius: 8px;background: #eee;overflow: hidden;}
.dp_list>li .con_box{flex: 1;width: 0;margin-left: 15px;line-height: 1;}
.dp_list>li .con_box .dp_name{font-weight: bold;}
.dp_list>li .con_box .dp_status{margin-top: 12px;font-size: 12px;color: #666666;}
.dp_list>li .con_box .dp_time{margin-top: 6px;font-size: 12px;color: #666666;}
.dp_list>li .opts{position: absolute;bottom: 15px;right: 15px;z-index: 2;font-size: 12px;color: #999;display: flex;align-items: center;}
.dp_list>li .opts>em{font-weight: bold;padding: 0 6px;}
.dp_list>li .opts>span{cursor: pointer;}
.dp_list>li .opts>span:hover{color: #f0413e;}
.btn_auth{cursor: pointer;font-size: 12px;margin-top: 8px;}
.btn_auth:hover{color: #f0413e;}

.opts_wrap{padding-top: 14px;}
	.opts_wrap .opts_line{margin-top: 30px;display: flex;align-items: center;}
	.opts_line .txt{width: 110px;text-align: right;font-size: 14px;}
	.opts_line .con{flex: 1;width: 0;margin-left: 22px;display: flex;align-items: center;}
	.opts_line .con .t{font-size: 14px;color: var(--red);text-decoration: underline;cursor: pointer;}
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.w180{width: 180px;}
	
	
	/* .el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;} */
	/*red border color*/
	.el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover{border-color: var(--red);}
	
	/*red color*/
	.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
</style>
<style>

.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner,.select_wrap .el-cascader .el-input .el-input__inner{padding-left: 76px;height: 34px;line-height: 34px;}
.select_wrap .el-select .el-input.is-focus .el-input__inner ,.select_wrap .el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}
.select_wrap .el-input__prefix, .el-input__suffix{display: flex;align-items: center;}
.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

.el-button:focus, .el-button:hover{border-color: var(--red);color: var(--red);background:#fff;}
.el-button--primary{background: var(--red) !important;color: #fff !important;border-color: #fff;}
.el-button--primary:focus, .el-button:hover{border-color: var(--red);}
.el-loading-spinner .el-loading-text{color:var(--red);}
.el-loading-spinner .path{stroke: var(--red);}
</style>
